<template>
  <div id="app-base-db" class="app-base-db">
    <div class="title">
      <div class="logo"></div>
      <div class="left_text" @click="backLogin">登录</div>
    </div>
    <div class="fbody">
      <div class="body-text">找回密码</div>


      <div class="pros">
        <div class="proseds">
          <div class="pros-one" :class="pindex == 1 ? 'pros-active' : ''">
            <div class="raund">1</div>
            <div>手机验证</div>
          </div>
          <div class="pros-two" :class="pindex == 2 ? 'pros-active' : ''">
            <div class="raund">2</div>
            <div>设置新密码</div>
          </div>
          <div class="pros-three" :class="pindex == 3 ? 'pros-active' : ''">
            <div class="raund">3</div>
            <div>成功</div>
          </div>
        </div>
        <div class="pros-line"></div>
      </div>

      <div class="input-plant" v-if="pindex == 1">
        <div>
          <input class="login-input" v-model="phone" placeholder="手机号码" />
        </div>
        <div class="plant-item">
          <input class="login-input" v-model="code" maxlength="10" placeholder="验证码" />
          <div class="pl get-s">|</div>
          <div class="pl get-code" @click="getCode" :class="codeText !== '获取验证码' ? 'code-end' : ''">{{codeText}}</div>
        </div>
      </div>

      <div class="input-plant" v-if="pindex == 2">
        <div>
          <input class="login-input" v-model="newPassWord" placeholder="设置新密码" />
        </div>
        <div class="plant-item">
          <input class="login-input" v-model="comPassWord"  placeholder="确认新密码" />
        </div>
      </div>

      <div class="input-plant" v-if="pindex == 3">
          <div class="icon-queren"></div>
          <div class="icon-text">设置成功，请牢记新的登录密码!</div>
      </div>

      <button class="login-button" @click="nextStep">{{ pindex == 3 ? "返回登录" : "下一步"}}</button>

    </div>
    <div class="header-line"></div>

    <div class="footer">
      <div class="f-text">黑龙江省缤丽电子商务有限公司版权所有：Powered by BBCbuilder V4.1.1</div>
      <div class="f-text f-top">营业执照</div>
      <div class="f-text f-color f-top">黑ICP备2020006196号</div>
    </div>
  </div>
</template>
<script>
import { ipcApiRoute , requestHttpPost , requestHttpGet} from '@/api/main'
import { message } from 'ant-design-vue';
export default {
  data() {
    return {
      phone:"",
      code:"",
      codeText:"获取验证码",
      codetimeS:60,
      dtime:null,
      pindex:1,
      newPassWord:"",
      comPassWord:""
    };
  },
  mounted() {

  },
  methods: {
    min(type) {
      this.$ipc.invoke(ipcApiRoute.action, type).then(result => {
        console.log(result)
      })
    },
    backLogin(){
      if (this.$ipc) {
        this.$ipc.invoke(ipcApiRoute.editWinSize, 1).then(result => {
          console.log(result)
        })
        this.$router.push('/login')
      } else {
        this.$router.push('/login')
      }
    },
    async nextStep(){
      if(this.pindex<4){
        this.pindex++
      }
      if(this.pindex == 2){
        let res = await requestHttpPost('https://ucenter.binliyoujia.com/index.php?ctl=Login&met=wxappYzCode&typ=json', {
            mobile:this.phone,
            user_code:this.code
        })
        if(res.msg == "success"){
          this.$message.success("验证成功");
        }else{
          this.pindex = 1
        }
        //提交验证
        return
      }
      if(this.pindex == 3){
        if(this.comPassWord !== this.newPassWord){
          this.$message.error("两次密码输入不一致");
          this.pindex = 2
          return 
        }
        let res = await requestHttpPost('https://ucenter.binliyoujia.com/index.php?ctl=Login&met=wxappresetPasswdNew&typ=json', {
            mobile:this.phone,
            user_password:this.comPassWord
        })
        console.log(res)
        if(res.status != 200){
          this.$message.error(res.msg);
          this.pindex = 2
        }
        // if(res.msg == "success"){
        //   this.$message.success("验证成功");
        // }else{
        //   this.pindex = 1
        // }
        return
      }
      if(this.pindex == 4){
        this.backLogin()
      }
    },
    async getCode(){
      let that = this
      if(this.codeText != "获取验证码"){
        return 
      }
      if(this.phone != ''){
        let res = await requestHttpPost('https://ucenter.binliyoujia.com//index.php?ctl=Login&met=wxapploginCode&typ=json', {
            mobile:this.phone,
            type:"forgetpwd"
        })
        if(res.msg == "发送成功"){
          that.$message.success("获取验证码成功");
          that.dtime = setInterval(() => {
            if(that.codetimeS>0){
              that.codeText = (that.codetimeS--)+"重新发送"
            }else{
              that.codeText = "获取验证码"
              that.codetimeS = 60
              clearInterval(that.dtime)
            }
          }, 1000);
        }else{
          
          that.$message.error(res.msg);
        }
      }else{
        message.error('请填写手机号码');
      }
      
      //获取验证码接口
    }
  }
};
</script>
<style lang="less" scoped>
.icon-queren{
  background-image: url('~@/assets/queren.png');
  background-size: 100%;
  width: 40px;
  height: 40px;
  margin: auto;
}
.icon-text{
  font-size: 14px;
  font-weight: normal;
  letter-spacing: 0em;
  color: #222222;
  margin-top: 20px;
}
.pl{
  position: absolute;
}
.code-end{
  color: #eee !important;
}
.get-code{
  color: #595959;
  right: 170px;
  top: 8px;
  font-size: 12px;
  cursor: pointer;
}
.get-s{
  color: #eee;
  right: 240px;
  top: 5px;
}
.plant-item{
  margin-top: 16px;
  position: relative;
}
.input-plant{
  margin-top: 50px;
}
.login-button {
  width: 260px;
  height: 32px;
  border-radius: 4px;
  background: linear-gradient(90deg, #FE5D42 0%, #ED2616 100%);
  border: none;
  color: #fff;
  margin-top: 21px;
  cursor: pointer;
}
.login-input {
  border-radius: 4px;
  opacity: 1;
  box-sizing: border-box;
  border: 1px solid #D8D8D8;
  width: 260px;
  height: 32px;
  padding-left: 10px;
  padding-right: 10px;
  outline: none;
  color: #999;
}
.login-input:focus {
  outline: none;
  border: 1px solid #D8D8D8;
}

.login-input::placeholder {
  color: #999999;
}
.raund{
  background: linear-gradient(90deg, #FF6044 0%, #ED2616 100%);
  width: 18px;
  height: 18px;
  border-radius: 50%;
  color: #fff;
  font-family: fantasy;
  font-size: 14px;
  text-align: center;
  line-height: 19px;
  margin-right: 8px;
}
.pros-one{
  width: 84px;
  height: 30px;
  display: flex;
}
.pros-two{
  width: 98px;
  height: 30px;
  display: flex;
}
.pros-three{
  width: 56px;
  height: 30px;
  display: flex;
}
.pros-active{
  border-bottom: 2px solid #ED2616;
  z-index: 1;
}
.proseds{
  display: flex;
  justify-content: space-between;
  height: 40px;
  width: 80%;
  margin: auto;
  padding-top: 10px;
}
.pros{
  position: relative;
  width: 100%;
  height: 40px;
  margin-top: 20px;
}
.pros-line{
  border: 1px solid #F7F7F7;
  height: 1px;
  width: 100%;
  position: absolute;
  bottom: 0px;
}
.body-text{
  font-family: none;
  font-size: 20px;
  letter-spacing: 2px;
  color: #222222;
  text-align: center;
  width: 100%;
  font-weight: 600;
}
.app-base-db{
  background: #F7F7F7;
}
.title{
  background: #ED2616;
  width: 100%;
  height: 62px;
  display: flex;
  justify-content: space-between;
  padding-left: 42px;
  padding-right: 42px;
}
.logo{
  background-image: url('~@/assets/logo.png');
  background-size: 100%;
  width: 70px;
  height: 20px;
  margin-top: 18px;
  -webkit-app-region: drag;
  -webkit-user-select: none;
}
.left_text{
  color: #fff;
  font-family: PingFang SC;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: 0em;
  margin-top: 18px;
  cursor: pointer;
}
.fbody{
  width: 960px;
  margin: auto;
  margin-top: 42px;
  border-radius: 10px;
  background-color: #fff;
  height: 475px;
  padding-top:70px;
  padding-left: 190px;
  padding-right: 190px;
}
.header-line{
  width: 100%;
  height: 1px;
  opacity: 1;
  background: #EEEEEE;
  margin-top: 70px;
}
.footer{
  width: 100%;
  padding: 24px;
}
.f-top{
  margin-top: 16px;
}
.f-text{
  font-family: PingFang SC;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: 0em;
  color: #222222;
}
.f-color{
  color: #999999;
}
</style>
